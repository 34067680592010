import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IStockItem } from '../interfaces/stock-item';
import { IBoardGrade } from '../interfaces/board-grade';
import { IStockColor } from '../interfaces/stock-color';
import { IDefectType } from '../interfaces/defect-type';
import { IFinish } from '../interfaces/finish';
import { IMillShiftPersonnel } from '../interfaces/mill-shift-personnel';
import { IStockItemType } from '../interfaces/stock-item-type';
import { ITrimSheetDetail } from '../interfaces/trim-sheet-detail';
import { IWarehouseLocation } from '../interfaces/warehouse-location';

export interface IStockItemDynamicControlsParameters {
    formGroup?: string;
    stockItemTypes?: IStockItemType[];
    warehouseLocations?: IWarehouseLocation[];
    boardGrades?: IBoardGrade[];
    finishes?: IFinish[];
    topColors?: IStockColor[];
    bottomColors?: IStockColor[];
    defectTypes?: IDefectType[];
    millShiftPersonnels?: IMillShiftPersonnel[];
    trimSheetDetails?: ITrimSheetDetail[];
}

export class StockItemDynamicControls {

    formGroup: string;
    stockItemTypes: IStockItemType[];
    warehouseLocations: IWarehouseLocation[];
    boardGrades: IBoardGrade[];
    finishes: IFinish[];
    topColors: IStockColor[];
    bottomColors: IStockColor[];
    defectTypes: IDefectType[];
    millShiftPersonnels: IMillShiftPersonnel[];
    trimSheetDetails: ITrimSheetDetail[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private stockitem?: IStockItem, additionalParameters?: IStockItemDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'StockItem';
        this.stockItemTypes = additionalParameters && additionalParameters.stockItemTypes || undefined;
        this.warehouseLocations = additionalParameters && additionalParameters.warehouseLocations || undefined;
        this.boardGrades = additionalParameters && additionalParameters.boardGrades || undefined;
        this.finishes = additionalParameters && additionalParameters.finishes || undefined;
        this.topColors = additionalParameters && additionalParameters.topColors || undefined;
        this.bottomColors = additionalParameters && additionalParameters.bottomColors || undefined;
        this.defectTypes = additionalParameters && additionalParameters.defectTypes || undefined;
        this.millShiftPersonnels = additionalParameters && additionalParameters.millShiftPersonnels || undefined;
        this.trimSheetDetails = additionalParameters && additionalParameters.trimSheetDetails || undefined;

        this.Form = {
            BoardGradeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Board Grade',
                name: 'BoardGradeId',
                options: this.boardGrades,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.stockitem && this.stockitem.BoardGradeId || null,
            }),
            BottomColorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bottom Color',
                name: 'BottomColorId',
                options: this.bottomColors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.stockitem && this.stockitem.BottomColorId || null,
            }),
            Bundles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bundles',
                name: 'Bundles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.stockitem && this.stockitem.Bundles || null,
            }),
            CaliperMax: new DynamicField({
                formGroup: this.formGroup,
                label: 'Caliper Max',
                name: 'CaliperMax',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.stockitem && this.stockitem.CaliperMax || null,
            }),
            CaliperMin: new DynamicField({
                formGroup: this.formGroup,
                label: 'Caliper Min',
                name: 'CaliperMin',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.stockitem && this.stockitem.CaliperMin || null,
            }),
            Color: new DynamicField({
                formGroup: this.formGroup,
                label: 'Color',
                name: 'Color',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.stockitem && this.stockitem.hasOwnProperty('Color') && this.stockitem.Color !== null ? this.stockitem.Color.toString() : '',
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.stockitem && this.stockitem.DateCreated || null,
            }),
            DefectTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Defect Type',
                name: 'DefectTypeId',
                options: this.defectTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.stockitem && this.stockitem.DefectTypeId || null,
            }),
            FinishId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Finish',
                name: 'FinishId',
                options: this.finishes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.stockitem && this.stockitem.FinishId || null,
            }),
            IsAvailable: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Available',
                name: 'IsAvailable',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.stockitem && this.stockitem.hasOwnProperty('IsAvailable') && this.stockitem.IsAvailable !== null ? this.stockitem.IsAvailable : true,
            }),
            IsPerfect: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Perfect',
                name: 'IsPerfect',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.stockitem && this.stockitem.hasOwnProperty('IsPerfect') && this.stockitem.IsPerfect !== null ? this.stockitem.IsPerfect : false,
            }),
            Length: new DynamicField({
                formGroup: this.formGroup,
                label: 'Length',
                name: 'Length',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.stockitem && this.stockitem.hasOwnProperty('Length') && this.stockitem.Length !== null ? this.stockitem.Length.toString() : '',
            }),
            LotNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Lot Number',
                name: 'LotNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(15) ],
                validators: { 'required': true, 'maxlength': 15 },
                value: this.stockitem && this.stockitem.hasOwnProperty('LotNumber') && this.stockitem.LotNumber !== null ? this.stockitem.LotNumber.toString() : '',
            }),
            MillShiftPersonnelId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mill Shift Personnel',
                name: 'MillShiftPersonnelId',
                options: this.millShiftPersonnels,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.stockitem && this.stockitem.MillShiftPersonnelId || null,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.stockitem && this.stockitem.hasOwnProperty('Name') && this.stockitem.Name !== null ? this.stockitem.Name.toString() : '',
            }),
            NetWeight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Net Weight',
                name: 'NetWeight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.stockitem && this.stockitem.NetWeight || null,
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.stockitem && this.stockitem.hasOwnProperty('Notes') && this.stockitem.Notes !== null ? this.stockitem.Notes.toString() : '',
            }),
            PoundsPerBundle: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pounds Per Bundle',
                name: 'PoundsPerBundle',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.stockitem && this.stockitem.PoundsPerBundle || null,
            }),
            Processed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Processed',
                name: 'Processed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.stockitem && this.stockitem.hasOwnProperty('Processed') && this.stockitem.Processed !== null ? this.stockitem.Processed : false,
            }),
            Qty: new DynamicField({
                formGroup: this.formGroup,
                label: 'Qty',
                name: 'Qty',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.stockitem && this.stockitem.Qty || null,
            }),
            Sides: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sides',
                name: 'Sides',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.stockitem && this.stockitem.Sides || null,
            }),
            StockIdNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Stock Id Number',
                name: 'StockIdNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.stockitem && this.stockitem.hasOwnProperty('StockIdNumber') && this.stockitem.StockIdNumber !== null ? this.stockitem.StockIdNumber.toString() : '',
            }),
            StockItemTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Stock Item Type',
                name: 'StockItemTypeId',
                options: this.stockItemTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.stockitem && this.stockitem.StockItemTypeId || null,
            }),
            TareWeight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tare Weight',
                name: 'TareWeight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.stockitem && this.stockitem.TareWeight || null,
            }),
            TopColorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Top Color',
                name: 'TopColorId',
                options: this.topColors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.stockitem && this.stockitem.TopColorId || null,
            }),
            TrimSheetDetailId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trim Sheet Detail',
                name: 'TrimSheetDetailId',
                options: this.trimSheetDetails,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.stockitem && this.stockitem.TrimSheetDetailId || null,
            }),
            WarehouseLocationId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Warehouse Location',
                name: 'WarehouseLocationId',
                options: this.warehouseLocations,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.stockitem && this.stockitem.WarehouseLocationId || null,
            }),
            Width: new DynamicField({
                formGroup: this.formGroup,
                label: 'Width',
                name: 'Width',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.stockitem && this.stockitem.hasOwnProperty('Width') && this.stockitem.Width !== null ? this.stockitem.Width.toString() : '',
            }),
        };

        this.View = {
            BoardGradeId: new DynamicLabel(
                'Board Grade',
                getMetaItemValue(this.boardGrades, this.stockitem && this.stockitem.hasOwnProperty('BoardGradeId') && this.stockitem.BoardGradeId !== null ? this.stockitem.BoardGradeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            BottomColorId: new DynamicLabel(
                'Bottom Color',
                getMetaItemValue(this.bottomColors, this.stockitem && this.stockitem.hasOwnProperty('BottomColorId') && this.stockitem.BottomColorId !== null ? this.stockitem.BottomColorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Bundles: new DynamicLabel(
                'Bundles',
                this.stockitem && this.stockitem.Bundles || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            CaliperMax: new DynamicLabel(
                'Caliper Max',
                this.stockitem && this.stockitem.CaliperMax || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
            ),
            CaliperMin: new DynamicLabel(
                'Caliper Min',
                this.stockitem && this.stockitem.CaliperMin || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
            ),
            Color: new DynamicLabel(
                'Color',
                this.stockitem && this.stockitem.hasOwnProperty('Color') && this.stockitem.Color !== null ? this.stockitem.Color.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.stockitem && this.stockitem.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DefectTypeId: new DynamicLabel(
                'Defect Type',
                getMetaItemValue(this.defectTypes, this.stockitem && this.stockitem.hasOwnProperty('DefectTypeId') && this.stockitem.DefectTypeId !== null ? this.stockitem.DefectTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            FinishId: new DynamicLabel(
                'Finish',
                getMetaItemValue(this.finishes, this.stockitem && this.stockitem.hasOwnProperty('FinishId') && this.stockitem.FinishId !== null ? this.stockitem.FinishId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsAvailable: new DynamicLabel(
                'Is Available',
                this.stockitem && this.stockitem.hasOwnProperty('IsAvailable') && this.stockitem.IsAvailable !== null ? this.stockitem.IsAvailable : true,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsPerfect: new DynamicLabel(
                'Is Perfect',
                this.stockitem && this.stockitem.hasOwnProperty('IsPerfect') && this.stockitem.IsPerfect !== null ? this.stockitem.IsPerfect : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Length: new DynamicLabel(
                'Length',
                this.stockitem && this.stockitem.hasOwnProperty('Length') && this.stockitem.Length !== null ? this.stockitem.Length.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            LotNumber: new DynamicLabel(
                'Lot Number',
                this.stockitem && this.stockitem.hasOwnProperty('LotNumber') && this.stockitem.LotNumber !== null ? this.stockitem.LotNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            MillShiftPersonnelId: new DynamicLabel(
                'Mill Shift Personnel',
                getMetaItemValue(this.millShiftPersonnels, this.stockitem && this.stockitem.hasOwnProperty('MillShiftPersonnelId') && this.stockitem.MillShiftPersonnelId !== null ? this.stockitem.MillShiftPersonnelId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Name: new DynamicLabel(
                'Name',
                this.stockitem && this.stockitem.hasOwnProperty('Name') && this.stockitem.Name !== null ? this.stockitem.Name.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            NetWeight: new DynamicLabel(
                'Net Weight',
                this.stockitem && this.stockitem.NetWeight || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Notes: new DynamicLabel(
                'Notes',
                this.stockitem && this.stockitem.hasOwnProperty('Notes') && this.stockitem.Notes !== null ? this.stockitem.Notes.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            PoundsPerBundle: new DynamicLabel(
                'Pounds Per Bundle',
                this.stockitem && this.stockitem.PoundsPerBundle || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Processed: new DynamicLabel(
                'Processed',
                this.stockitem && this.stockitem.hasOwnProperty('Processed') && this.stockitem.Processed !== null ? this.stockitem.Processed : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Qty: new DynamicLabel(
                'Qty',
                this.stockitem && this.stockitem.Qty || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            ),
            Sides: new DynamicLabel(
                'Sides',
                this.stockitem && this.stockitem.Sides || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            ),
            StockIdNumber: new DynamicLabel(
                'Stock Id Number',
                this.stockitem && this.stockitem.hasOwnProperty('StockIdNumber') && this.stockitem.StockIdNumber !== null ? this.stockitem.StockIdNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            StockItemTypeId: new DynamicLabel(
                'Stock Item Type',
                getMetaItemValue(this.stockItemTypes, this.stockitem && this.stockitem.hasOwnProperty('StockItemTypeId') && this.stockitem.StockItemTypeId !== null ? this.stockitem.StockItemTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            TareWeight: new DynamicLabel(
                'Tare Weight',
                this.stockitem && this.stockitem.TareWeight || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            TopColorId: new DynamicLabel(
                'Top Color',
                getMetaItemValue(this.topColors, this.stockitem && this.stockitem.hasOwnProperty('TopColorId') && this.stockitem.TopColorId !== null ? this.stockitem.TopColorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            TrimSheetDetailId: new DynamicLabel(
                'Trim Sheet Detail',
                getMetaItemValue(this.trimSheetDetails, this.stockitem && this.stockitem.hasOwnProperty('TrimSheetDetailId') && this.stockitem.TrimSheetDetailId !== null ? this.stockitem.TrimSheetDetailId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            WarehouseLocationId: new DynamicLabel(
                'Warehouse Location',
                getMetaItemValue(this.warehouseLocations, this.stockitem && this.stockitem.hasOwnProperty('WarehouseLocationId') && this.stockitem.WarehouseLocationId !== null ? this.stockitem.WarehouseLocationId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Width: new DynamicLabel(
                'Width',
                this.stockitem && this.stockitem.hasOwnProperty('Width') && this.stockitem.Width !== null ? this.stockitem.Width.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
